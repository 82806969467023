import Vue from "vue";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import Loading from "@/components/widgets/Loading.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);

export default {
  data: function () {
    return {
      role: "",
      branch: "",
      currentScan: null,
      currentRoll: null,
      currentIndex: null,
      currentBranchIndex: null,
      show: false,
      allResult: null,
      loading: false,
      qrCode: "sangthai",
      qrCodeBase64: "",
      buyer: {
        code: "",
        name: "",
        address: "",
        note: "",
      },
      document: {
        no: "",
        date: "",
        condition: "ชำระภายใน 7 วัน",
        credit: "30 วัน",
        duedate: "25/09/2022",
        payment_terms: null,
      },
      seller: {
        name: "เกริกวิทย์ ผลดี",
        address:
          "เลขที่ 72 ถ.แจ้งวัฒนะ ต.ตลาดบางเขน อ.หลักสี่ กรุงเทพฯ รหัสไปรษณีย์ 10210",
        taxno: "12345678999",
        tel: "0-3483-0032-7",
      },
      products: [],
      groupByProduct: [],
      note: "ทดสอบ",
      discount: 150,
      nettotal: 0,
      afterDisc: 0,
    };
  },
  components: {
    Loading,
    "qr-code": VueQRCodeComponent,
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData();
    });
    this.getRole()
  },
  methods: {
    async onSubmit() {
      let isOkToSubmit = true;
      for (var ob in this.groupByProduct) {
        if (this.groupByProduct.hasOwnProperty(ob)) {
          let obj = this.groupByProduct[ob];
          obj.forEach((element) => {
            if(!element.scanned && !element.has_sent){
              isOkToSubmit = false
            }
          });
        }
      }

      if(isOkToSubmit){
        this.confirmSend()
      } else {
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">กรุณาสแกนสินค้าให้ครบก่อนกดส่งของ</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true
        })
      }
    },
    async onScan(){
      try {
        this.loading = true
        const result = await Vue.prototype.$axios.patch(`${Vue.prototype.$host}/api/v1/scan/${this.currentScan}`)

        this.loading = false
        if(result.status === 200) {
          this.groupByProduct[this.currentBranchIndex][this.currentIndex][
            "scanned"
          ] = true;
  
          (this.currentRoll = null),
          (this.currentScan = null),
            (this.currentIndex = null),
            (this.currentBranchIndex = null),
            (this.show = false);

            alert("สแกนสำเร็จ");
        }
      } catch (error) {
        this.loading = false
        let message = error.response? error.response.data.message: error.message
        alert(message);

        // this.onExceptionHandler(message)
      }
    },
    async confirmSend() {
      try {
        this.loading = true
        const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/pickup/${this.allResult.data.uuid}`)

        this.loading = false
        if(result.status === 200) {
          this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
              '</div>'+
            '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true
          }).then(result => {
            if (result) {
              this.$router.push("/order-centric");
            }
          })
        }
      } catch (error) {
        this.loading = false
        let message = error.response? error.response.data.message: error.message
        this.onExceptionHandler(message)
      }
    },
    onInit(promise) {
      promise.then(console.log).catch(console.error);
    },
    onDecode(decodedString) {
      let uuid = decodedString.split("/").pop();
      if (this.currentRoll.uuid === uuid) {
        this.onScan()
      } else {
        alert("กรุณาตรวจสอบม้วนใหม่อีกครั้ง");
        // this.$swal({
        //   width: "29rem",
        //   padding: '1.825em',
        //   html: '<div class="d-flex flex-row align-items-center">' +
        //     '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
        //     '<div class="d-flex flex-column">'+
        //     '<span class="font-bold text-base mb-1 text-left">แจ้งเตือน</span>'+
        //     '<span class="text-base text-left text-gray-500">กรุณาตรวจสอบม้วนใหม่อีกครั้ง</span>'+
        //     '</div>'+
        //   '</div>',
        //     allowOutsideClick: false,
        //     focusConfirm: false,
        //     customClass: {
        //       popup: 'swal2-custom-rounded',
        //       closeButton: 'text-3xl swal2-custom-close',
        //       actions: 'justify-content-end',
        //       contant: 'd-flex flex-row justify-content-around px-2',
        //       title: 'd-none',
        //       confirmButton: "btn btn-info py-2.5 rounded-lg text-white font-prompt-light text-sm font-normal",
        //     },
        //     confirmButtonText: "ตกลง",
        //     showCancelButton: false,
        //     showCloseButton: true,
        // })
      }
    },
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    async onInitIFrame() {
      this.loading = true;
      await this.createImage();
      const child = document.querySelectorAll(`#qr-code-${this.qrCode} > img`);
      if (child) {
        this.qrCodeBase64 = child[0].src;
      }
      setTimeout(async () => {
        const processJspdf = async () => {
          return new Promise(async (resolve, reject) => {
            const doc = new jsPDF("p", "mm", "a4");
            doc.addFileToVFS(
              "THSarabunNew.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunNormal
            );
            // add custom font to file
            doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
            doc.setFont("THSarabunNew", "normal");

            doc.addFileToVFS(
              "THSarabunNew Bold.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunBold
            );
            // add custom font to file
            doc.addFont("THSarabunNew Bold.ttf", "THSarabunNew", "bold");
            doc.setFont("THSarabunNew", "bold");

            const vm = this;
            const head = await this.onInitHeader();
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 0, left: 8, right: 8 },
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              head: head,
              body: [],
              willDrawCell(data) {
                if (data.cell.raw && data.section === "head") {
                  if (data.cell.raw.content === "breaklineheader") {
                    const width = doc.internal.pageSize.getWidth() - 16;
                    data.doc.setLineWidth(0.2);
                    data.doc.setDrawColor(100, 116, 139);
                    data.doc.line(
                      data.cell.x,
                      data.cell.y + 5,
                      data.cell.x + width,
                      data.cell.y + 5
                    );
                  }
                  if (data.cell.raw.content === "imageQrCode") {
                    const w = data.cell.contentWidth + 2;
                    data.doc.addImage(
                      vm.qrCodeBase64,
                      "PNG",
                      data.cell.x + w,
                      data.cell.y,
                      18,
                      18
                    );
                  }
                }
              },
            });

            const headTable = await this.onInitHeaderTable();
            const body = await this.onInitBody();
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 8, left: 8, right: 8 },
              startY: doc.lastAutoTable.finalY + 6,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              head: headTable,
              body: body,
              didDrawCell(data) {
                if (data.cell.raw && data.section === "body") {
                  if (data.cell.raw.barcode && data.cell.raw.content) {
                    data.doc.addImage(
                      data.cell.raw.barcode,
                      "PNG",
                      data.cell.x + 3,
                      data.cell.y + 2,
                      14,
                      14
                    );
                  }
                }
              },
            });

            const footer = await this.onInitFooter(doc);
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 8, left: 8, right: 8 },
              startY: doc.lastAutoTable.finalY,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {},
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "bottom",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              foot: footer,
            });
            resolve(doc);
          });
        };
        const doc = await processJspdf();
        let element = document.getElementById("purchase-order");
        if (element) {
          element.setAttribute("src", doc.output("bloburl"));
          setTimeout(() => {
            this.loading = false;
            this.onIframePrint();
          }, 1000);
        }
      }, 1000);
    },
    onIframePrint() {
      let iFrame = document.getElementById("purchase-order");
      iFrame.focus();
      iFrame.contentWindow.print();
    },
    createImage() {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const timer = (ms) => new Promise((res) => setTimeout(res, ms));
          const imageUrl = document.getElementById("imageUrl");
          if (imageUrl) {
            for (let index = 0; index < this.products.length; index++) {
              const element = this.products[index];
              const child = document.querySelectorAll(
                `#${element.detail.coil} > img`
              );
              if (child.length > 0) {
                const image = child[0].src;
                const span = document.createElement(
                  `imageUrl-${element.detail.coil}`
                );
                span.setAttribute("id", `imageUrl-${element.detail.coil}`);
                span.innerHTML = image;
                imageUrl.appendChild(span);
                await timer(800);
              }
            }
          }
          resolve(imageUrl);
        }, 1500);
      });
    },
    async onInitData() {
      let localApp = this
      this.busy = true

      try {
        const param = {
          _id: this.$route.params.id,
        };
        const result = await this.$store.dispatch(
          "Documents/getPreemptionDocument",
          param,
          { root: true }
        );

        if(result.data.data.status === 1){
          this.$router.push('/order-centric')
        }
 
        this.busy = false;
        if(result.data.data.status >= 3 && result.data.data.status !== 9) {
          this.onOpenReceiveOrder(result.data.data.uuid)
          return
        }

        localApp.allResult = result.data
        // Accepts the array and key
        const groupBy = (array, key) => {
          // Return the end result
          return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
          }, {}); // empty object is the initial value for result object
        };

        localApp.groupByProduct = groupBy(result.data.products, "destination");

        let res = result.data.data;

        if(res.doc_type === 1){
          let branch_code = res.branch_name.code;
          let branch_name = res.branch_name.name;
          let branch_address = res.branch_name.address;

          this.buyer.code = branch_code;
          this.buyer.name = branch_name;
          this.buyer.address = branch_address;
          this.buyer.note = note;
        }

        let document_no = "PO-" + res.document_no;
        let date = res.date;
        let note = res.note;
        let payment_terms = res.payment_terms;

        this.document.no = document_no;
        this.document.date = date;
        this.document.payment_terms = payment_terms;

        this.products = result.data.products
      } catch (error) {
        let message = error.response? error.response.data.message: error.message
        this.onExceptionHandler(message)
        this.busy = false
        return []
      }
    },
    onInitHeaderTable() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "No",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 20,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top",
              },
            },
            {
              content: "รหัสสินค้า/รายละเอียด",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              },
            },
            {
              content: "นน./ม.",
              colSpan: 3,
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 20,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "แถว",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 23,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "คลัง",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 23,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "จำนวน",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 23,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
            {
              content: "หมายเหตุ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 30,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              },
            },
          ],
        ];
        resolve(head);
      });
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "ใบจ่ายสินค้าภายใน",
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "imageQrCode",
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 20,
                fontSize: 26,
                textColor: [255, 255, 255],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: "Internal Purchase Order",
              colSpan: 7,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 22,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: "รหัสสาขา " + this.buyer.code,
              colSpan: 4,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "เลขที่เอกสาร:",
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.document.no,
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: this.buyer.name,
              colSpan: 4,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "วันที่เอกสาร:",
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: new Date(this.document.date).toLocaleDateString(
                "th-TH",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              ),
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: `${this.buyer.address}`,
              colSpan: 4,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: `เงื่อนไขการชำระ: `,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.document.payment_terms === 1 ? "เงินสด" : "เครดิต",
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 40,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "right",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: `หมายเหตุ: ${
                this.document.note != null ? this.document.note : ""
              }`,
              colSpan: 7,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
          ],
          [
            {
              content: "breaklineheader",
              colSpan: 7,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [255, 255, 255],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
          ],
        ];
        resolve(head);
      });
    },
    onInitBody() {
      return new Promise(async (resolve, reject) => {
        const data = this.products;
        const body = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const dataUrl = document.getElementById(
            `imageUrl-${element.detail.coil}`
          )?.innerHTML;
          if (dataUrl) {
            let obj = {};
            obj = [
              {
                content: element.detail.coil,
                barcode: dataUrl,
                styles: {
                  cellWidth: 20,
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [255, 255, 255],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "center",
                  valign: "top",
                },
              },
              {
                content:
                  element.detail.coil +
                  " " +
                  element.detail.size +
                  " " +
                  parseInt(element.detail.length).toLocaleString("th-TH") +
                  "M",
                styles: {
                  cellWidth: "auto",
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "left",
                  valign: "top",
                },
              },
              {
                content: (
                  parseFloat(element.detail.net_weight) /
                  parseFloat(element.detail.length)
                ).toFixed(4),
                colSpan: 3,
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  cellWidth: 20,
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "right",
                  valign: "top",
                },
              },
              {
                content: element.detail.row ? element.detail.row : "",
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  cellWidth: 23,
                  fontSize: 15,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "right",
                  valign: "top",
                },
              },
              {
                content: element.detail.warehouse
                  ? element.detail.warehouse
                  : "",
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "right",
                  valign: "top",
                },
              },
              {
                content: parseFloat(element.detail.net_weight).toLocaleString(
                  "th-TH",
                  {
                    minimumFractionDigits: 2,
                  }
                ),
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  fontSize: 15,
                  cellWidth: 23,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "right",
                  valign: "top",
                },
              },
              {
                content: element.detail.note != null ? element.detail.note : "",
                styles: {
                  lineWidth: {
                    left: 0.1,
                    right: 0.1,
                  },
                  fontSize: 15,
                  cellWidth: 30,
                  fillColor: [255, 255, 255],
                  textColor: [15, 23, 42],
                  cellPadding: {
                    left: 2,
                    right: 2,
                    top: 1,
                    bottom: 1,
                  },
                  fontStyle: "normal",
                  halign: "right",
                  valign: "top",
                },
              },
            ];
            body.push(obj);
          }
        }

        let emptyBody = {};
        emptyBody = [
          {
            content: "",
            styles: {
              cellWidth: 15,
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle",
            },
          },
          {
            content: "",
            styles: {
              cellWidth: "auto",
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle",
            },
          },
          {
            content: "",
            colSpan: 3,
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 23,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 23,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: "normal",
              halign: "right",
              valign: "top",
            },
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 23,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: "normal",
              halign: "right",
              valign: "top",
            },
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 23,
              fontSize: 1,
              textColor: [0, 0, 0],
              fontStyle: "normal",
              halign: "center",
              valign: "top",
            },
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 30,
              fontSize: 1,
              textColor: [0, 0, 0],
              fontStyle: "normal",
              halign: "center",
              valign: "top",
            },
          },
        ];

        if (body.length <= 12) {
          const number = 12 - body.length;
          for (let index = 0; index < number; index++) {
            body.push(emptyBody);
          }
        }
        resolve(body);
      });
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const footer = [];
        const weightTotal = [
          {
            content: `น้ำหนักทั้งหมด = ${parseFloat(
              this.sumOfWeight
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
            })} kg.`,
            colSpan: 7,
            styles: {
              lineWidth: {
                top: 0.1,
              },
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const grandTotal = [
          {
            content: `น้ำหนักรวม = ${parseFloat(this.sumOfGross).toLocaleString(
              "th-TH",
              {
                minimumFractionDigits: 2,
              }
            )} kg.`,
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport1 = [
          {
            content:
              "ทะเบียนรถ...................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport2 = [
          {
            content:
              "บริษัทขนส่ง..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport3 = [
          {
            content:
              "วัน/เดือน/ปี..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport4 = [
          {
            content:
              "ผู้ตรวจสอบ..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        footer.push(weightTotal);
        footer.push(grandTotal);
        footer.push(transport1);
        footer.push(transport2);
        footer.push(transport3);
        footer.push(transport4);
        resolve(footer);
      });
    },
    onOpenReceiveOrder(uuid) {
      this.$router.push("/order-centric/receive/" + uuid)
    }
  },
  computed: {
    sumOfWeight() {
      return this.products.reduce((sum, transaction) => {
        return (sum += parseInt(transaction.detail.net_weight))
      }, 0)
    },
    sumOfGross() {
      return this.products.reduce((sum, transaction) => {
        return (sum += parseInt(transaction.detail.gross_weight))
      }, 0)
    }
  }
}
